// @ts-ignore
import { create } from 'axios';
import { getTokenAfterLogin } from '../authentication/msal';
const { env } = require('../env/env');

/**@type {import('axios').AxiosInstance} */
const instance = create({
    baseURL: env.REACT_APP_API_URL,
    responseType: 'json',
});

instance.interceptors.request.use(addTokenToConfig, error => {
    return Promise.reject(error);
});

async function addTokenToConfig(config) {
    let token = await getTokenAfterLogin();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}

export default instance;
