import * as Msal from 'msal';
import Cookies from 'js-cookie';
const { env } = require('../env/env');

/** @type {import('msal').Configuration} */
const msalConfig = {
    auth: {
        clientId: env.REACT_APP_CLIENT_ID,
        authority: env.REACT_APP_AUTHORITY,
        validateAuthority: false,
        navigateToLoginRequestUrl: true,
        postLogoutRedirectUri: 'https://www.liberty.co.za',
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true,
    },
};

/** @type {import('msal').AuthenticationParameters} */
const reqParams = {
    scopes: [env.REACT_APP_SCOPE],
};

const clientApplication = new Msal.UserAgentApplication(msalConfig);
clientApplication.handleRedirectCallback((error, response) => {
    if (error) {
        if (error.message.indexOf('AADB2C90118') >= 0) {
            //User clicked forgot password
            clientApplication.authority = env.REACT_APP_AUTHORITY_RESET;
            redirectToLogin();
            return;
        }
    }
});

export function redirectToLogin() {
    clientApplication.loginRedirect(reqParams);
}

export async function tryGetTokenSilent() {
    try {
        const token = await clientApplication.acquireTokenSilent(reqParams);
        return token;
    } catch (err) {}
}

export function getAccount() {
    return clientApplication.getAccount();
}

export function logout() {
    return clientApplication.logout();
}

export async function getTokenPopup() {
    return await clientApplication.acquireTokenPopup(reqParams);
}

export async function getTokenAfterLogin() {
    try {
        var sessionToken =  Cookies.get('msal.4ac593bb-4c54-4c30-84cc-bc71c642cd43.idtoken');
        if(sessionToken)
        {
            const jwtPayload = JSON.parse(window.atob(sessionToken.split('.')[1]));
            const isExpired = Date.now() >= jwtPayload.exp * 1000;
            if(isExpired)
            {
                const token = await clientApplication.acquireTokenSilent(reqParams);
                return token.accessToken;
            }else{
                return sessionToken; 
            }
        }
        else{
            const token = await clientApplication.acquireTokenSilent(reqParams);
            return token.accessToken;       
        }
    } catch (err) {
        clientApplication.loginRedirect(reqParams);

        // the save claim operation exepects a token. If there is no token, we refrain from popping a login screen. Rather, we redirect
        // to login and redirect back. Our postlogin implementation handles the session and returns the user to the same place they were.
        // the 5 sec delay here, allows the redirect to execute before the save claim function requires a token. The save claim operation
        // is stopped indefinitely
        return new Promise(resolve => setTimeout(resolve, 5000));
    }
}
